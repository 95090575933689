import RichContentViewerComponent from '../components/RichContentViewer/viewer/RichContentViewer';
import RichContentViewerController from '../components/RichContentViewer/viewer/RichContentViewer.controller';


const RichContentViewer = {
  component: RichContentViewerComponent,
  controller: RichContentViewerController
};


export const components = {
  ['RichContentViewer']: RichContentViewer
};

